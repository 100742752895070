@import "src/assets/style";

.P-homePage {
  position: relative;
  z-index: 0;
  height: calc(100%);
  padding-top: 4.1vw;
  display: flex;

  &:before {
    content: "";
    background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/homePage/background.webp");
    height: auto;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: $secondary-color-purple;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    z-index: -2;
  }

  main.G-container {
    flex-direction: row-reverse;
  }

  @include screen-portrait {
    //& > * {
    //  transform: scale(.6);
    //}

    main.G-container {
      //flex-direction: row-reverse;
      display: flex;
      flex-direction: column;
      height: calc(100% - 29.3vw);
    }
  }
  @include screen-portrait {
    padding-top: 16.3vw;
    &:before {
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/homePage/backgroundMobile.webp");
    }
  }
  @include screen-landscape {
    &:before {
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/homePage/background.webp");
    }
  }

  .P-exclusiveButton {
    text-transform: uppercase;
    color: $white;
    font-size: 1.4vw;
    font-weight: bold;
    letter-spacing: 0.6vw;
    padding: 0.25vw 0.9vw 0.25vw 1.5vw;
    background-color: $main-color;
    width: fit-content;
    text-align: center;
    border-radius: 3vw;
    margin: 1.3vw auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @include screen-portrait {
      font-size: 3.5vw;
      padding: 2vw 2vw 2vw 4vw;
      letter-spacing: 1vw;
      border-radius: 50%;
      margin-bottom: 4.1vw;
      position: fixed;
      top: 31vw;
      aspect-ratio: 1;
    }
  }

  .P-title {
    cursor: default;

    margin-top: 1.4vw;
    font-family: "Questrial", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 3.8vw;
    line-height: 3.9vw;

    color: $Contrast_colors-dark;
    @include screen-portrait {
      font-size: 7vw;
      text-align: center;
      position: fixed;
      top: 24vw;
      left: 2vw;
      transform-origin: left;

      line-height: 12vw;
    }

    .P-futu {
      font-family: $Aclonica-Regular;
      font-size: 5.9vw;
      font-weight: 400;
      line-height: 6.7vw;
      letter-spacing: 0em;
      text-align: left;
      text-transform: uppercase;
      @include screen-portrait {
        font-size: 9.7vw;
        line-height: 14vw;
      }
    }

    .P-rili {
      font-size: 4.2vw;
      @include screen-portrait {
        font-size: 6.63vw;
      }
    }
  }

  .P-socialIconsContainer {
    width: 90%;
    margin: 1vw auto;

    .P-socialIconsSection {
      a {
        height: auto;
        width: 60%;
        aspect-ratio: 1;

        img {
          width: 100%;
        }
      }
    }
  }

  .P-scrollContainer {
    width: 60%;
    display: flex;
    flex-direction: column;

    @include screen-portrait {
      position: relative;
      display: flex;
      //top: calc(70vh - 50vw);
      //bottom: 11vw;
      margin-bottom: 30px;
      margin-top: auto;
      width: 100%;
      align-items: flex-end;
    }

    .P-socials {
      margin: 0 0 -13vw auto;
    }

    .P-streamMe {
      font-size: 2.23vw;
      text-align: right;
      color: $white;
      text-transform: lowercase;
      @include screen-portrait {
        font-size: 6.6vw;
        margin-bottom: -3vw;
      }
    }

    .P-musicContainer {
      align-items: flex-end;
      max-width: 100%;
      margin-top: auto;
      margin-bottom: 0;
    }

    .P-musicMainItem {
      margin-top: 3.5vw;
      margin-bottom: 1.5vw;
      margin-right: 1.5vw;

      &.open {
        position: relative;
        z-index: 9999999;
      }

      @include screen-portrait {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0px;
      }
    }

    .P-musicItemsContainer {
      display: flex;
      align-items: flex-end;
      overflow-x: auto !important;
      -webkit-overflow-x: auto !important;
      overflow-y: visible !important;
      -webkit-overflow-y: visible !important;

      padding: 0.7vw 0 0 0.5vw;
      margin-left: -0.5vw;
      height: auto;
      width: 100%;

      &:has(.open) {
        overflow-x: hidden !important;
        -webkit-overflow-x: hidden !important;

        overflow-y: visible !important;
        -webkit-overflow-y: visible !important;
      }

      /*--mask: linear-gradient(to right,
              rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) 5%,
              rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 95%
      ) 100% 100% / 100% 100% repeat-x;
      mask: var(--mask);*/

      @include screen-portrait {
        margin-bottom: 0;

        height: fit-content;
        padding: 2.5vw 2.5vw 0;
      }

      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .P-musicItem {
        margin-bottom: 1.5vw;
        min-width: 7.5vw;
        white-space: nowrap;
        width: 33%;
        height: fit-content;

        .P-audioPopUp {
          display: none;
        }

        &.open {
          position: relative;
          z-index: 9999999;
          margin-right: 4vw;
        }

        @include screen-portrait {
          margin-bottom: 0;
          min-width: 20vw;
        }
      }
    }
  }

  .P-actionsContainer {
    width: 40%;
    margin-top: 1.5vw;
    display: flex;
    flex-direction: column;
    @include screen-portrait {
      width: 50%;
      margin-top: 4.5vw;
    }

    .P-buttons {
      margin-left: auto;

      text-align: center;

      width: 100%;

      .comingSoon:before {
        font-size: 1.6vw;
      }

      @include screen-portrait {
        width: 45vw;
        .comingSoon:before {
          font-size: 3.6vw;
          top: -1vw;
        }
      }

      &:has(.yoga) {
        @include screen-portrait {
          position: fixed;

          top: 17vw;
          left: 100%;
          transform: translateX(calc(-100% - 2vw));
        }
      }

      &:has(.live) {
        margin-top: 1.1vw;
        @include screen-portrait {
          position: fixed;
          top: 41vw;
          left: 100%;
          transform: translateX(calc(-100% - 2vw));
        }
      }

      .G-futu {
        font-size: 2.7vw;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
        @include screen-portrait {
          font-size: 6vw;
        }
      }

      .G-yoga {
        font-size: 3.5vw;
        @include screen-portrait {
          font-size: 8vw;
        }
      }

      .G-live {
        font-size: 2.23vw;
        @include screen-portrait {
          font-size: 4.6vw;
        }
      }

      .P-description {
        font-size: 1.46vw;
        color: $Contrast-colors-dark;
        font-family: $KronaOne-Regular;
        font-weight: 400;
        margin-top: -0.5vw;
        text-shadow: 0.1vw 0.1vw 0px $secondary-color-purple;
        @include screen-portrait {
          font-size: 4vw;
        }
      }
    }
  }

  .P-message {
    margin-top: auto;
    margin-bottom: 4.5vw;
    text-align: end;

    @include screen-portrait {
      position: relative;
      top: auto;
      text-align: start;
      margin-top: 0;
    }

    .P-data {
      color: $Contrast_colors-dark;
      font-size: 1.8vw;
      font-family: $KronaOne-Regular;
      @include screen-portrait {
        font-size: 3.8vw;
      }
    }

    .P-mainText {
      color: $white;
      font-size: 3.3vw;
      line-height: 1;
      font-family: $KronaOne-Regular;
      text-transform: uppercase;
      @include screen-portrait {
        font-size: 7.1vw;
      }
    }

    .P-secondaryText {
      color: $Contrast_colors-dark;
      font-size: 1.5vw;
      font-family: $FiraCode-Bold;
      @include screen-portrait {
        font-size: 3.8vw;
      }
    }
  }

  .P-triangleButton {
    position: relative;
    z-index: 1;
    background-color: transparent;
    //padding-right: 3vw;
    padding-bottom: 0.4vw;
    font-size: 0.8vw;
    width: 11vw;
    height: 5vw;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-left: auto;
    color: $white;
    font-weight: bold;
    font-family: $FiraCode-Bold;

    &:before {
      content: "";
      left: 100%;
      top: 100%;
      position: absolute;
      width: 0%;
      height: 0%;
      display: block;
      //border-width: 7vw;
      //border-style: solid;
      z-index: -1;
      box-sizing: border-box;

      border: 4vw solid black;
      border-color: transparent transparent $main-color $main-color;

      transform-origin: 0 0;
      transform: rotate(135deg);

      box-shadow: -0.2vw 0.2vw 0.3vw 0 rgba(255, 255, 255, 0.4);
    }
  }
}
